import styled from 'styled-components'
import { transparentize } from 'polished'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { Heading3 } from '../../styles/vars/textStyles.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { easings } from '../../styles/vars/easings.style'

export const WorkListItemMain = styled.li`
  position: relative;
  border-bottom: 0.1rem solid transparent;
  transition: border-color 0.2s ${easings.inOut.default};
  will-change: border-color;

  [data-inview='true'] & {
    border-color: ${transparentize(0.4, colors.grey)};
  }

  ${PageTransitionLinkMain} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ${clamp('padding-top', 50, 64)}
    ${clamp('padding-bottom', 20, 64)}
    text-align: left;
    border: none;
    background-color: transparent;
  }

  ${Heading3} {
    flex: 1;
  }
`

export const WorkListItemContent = styled.div`
  position: relative;
  z-index: 1;
`

export const WorkListItemIcon = styled.div`
  ${mq.tabletL} {
    display: none;
  }
`

export const WorkListItemBorder = styled.div`
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  height: 0.1rem;
  background-color: ${colors.light};
  transform: ${({ show }) => (show ? 'scaleX(1)' : 'scaleX(0)')};
  transform-origin: ${({ show }) => (show ? '0% 50%' : '100% 50%')};
  transition: transform 0.6s ${easings.inOut.default};
  will-change: transform;
`

export const WorkListItemText = styled.span`
  display: block;
  max-width: 8em;
`

export const WorkListItemMaskWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  perspective: 100rem;
  pointer-events: none;
`

export const WorkListItemMask = styled.div.attrs(({ offset }) => {
  const rotateMax = 5
  const transform = `translate(-10%, -50%) rotateZ(${
    -offset[1] * (rotateMax / 4)
  }deg) rotateY(${offset[0] * rotateMax}deg)`

  return {
    style: {
      transform,
    },
  }
})`
  position: absolute;
  top: 50%;
  right: 0;
  display: none;
  ${clamp('width', 190, 280, breakpoints.desk, breakpoints.deskL)}
  pointer-events: none;
  overflow: hidden;
  z-index: 2;

  ${mq.desk} {
    display: block;
  }
`

export const WorkListItemImage = styled.div`
  position: relative;
  transform: ${({ open }) => (open ? 'translateY(0%)' : 'translateY(110%)')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: transform 0.4s ${easings.inOut.default},
    opacity 0.1s ${easings.inOut.default};
  will-change: transform, opacity;
  border-radius: 1.5rem;
  overflow: hidden;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
`

export const WorkListFlare = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const WorkListFlareSVG = styled.svg.attrs(({ mousePos }) => {
  const transform = `translate(-50%, -${50 - mousePos[0] * 10}%)`

  return {
    style: {
      transform,
    },
  }
})`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
`
