import styled from 'styled-components'

const rotateMax = 6

export const BlurImageWrapper = styled.div`
  perspective: 100rem;
`

export const BlurImageMain = styled.div.attrs(({ offset, hovered }) => {
  const transform = `rotateX(${offset[1] * rotateMax}deg) rotateY(${
    offset[0] * rotateMax
  }deg)`
  const transition = hovered ? 'transform 0.1s linear' : 'transform 0.5s ease'

  return {
    style: {
      transform,
      transition,
    },
  }
})`
  position: relative;
  width: 100%;
  border-radius: 1.5rem;
  overflow: hidden;
  will-change: transform;

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 118.42105263%;
  }
`

export const BlurImageSVG = styled.svg`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .mask,
  .blur,
  .flare {
    transition: opacity 0.5s ease;
  }

  .flare {
    mix-blend-mode: lighten;
  }

  .gradient stop {
    transition: all 0.5s ease;
  }
`
