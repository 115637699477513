import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'

export const Jobs = styled.ul`
  list-style: none;
`

export const JobsItem = styled.li`
  ${clamp('padding-top', 30, 34)}
`
