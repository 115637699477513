import styled from 'styled-components'
import { transparentize } from 'polished'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'

export const CareersItemMain = styled.a`
  position: relative;
  display: block;
  ${clamp('padding-top', 20, 30)}
  ${clamp('padding-bottom', 20, 30)}
  text-align: left;
  ${clamp('font-size', 18, 26)}
  line-height: 1.2;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  border-bottom: 0.1rem solid ${transparentize(0.4, colors.grey)};
  transition: border-bottom-color 0.2s ease;

  :after {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    right: 0;
    left: 0;
    display: block;
    height: 0.1rem;
    background-color: ${colors.light};
    transform: ${({ hovered }) => (hovered ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: ${({ hovered }) => (hovered ? '0% 50%' : '100% 50%')};
    transition: transform 0.4s ${easings.inOut.default};
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;

    ${mq.desk} {
      display: none;
    }
  }
`
