import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Container from '../_layouts/Container'
import ServicesItem from '../ServicesItem'
import TetrisPieceA from '../_svgs/TetrisPieceA'
import TetrisPieceB from '../_svgs/TetrisPieceB'
import TetrisPieceC from '../_svgs/TetrisPieceC'
import { Heading3, TextBody } from '../TextStyles'
import Spacer from '../Spacer'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import { ContextState } from '../../context/Context'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { ServicesMain, ServicesWrapper } from './index.style'

const tetrisPieces = [<TetrisPieceA />, <TetrisPieceB />, <TetrisPieceC />]

const Services = ({ items }) => {
  const [vw, setVw] = useState(null)
  const store = useContext(ContextState)

  const handleResize = () => {
    setVw(window.innerWidth)
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ServicesWrapper id="services">
      <div data-scroll data-scroll-call="servicesA" data-scroll-repeat>
        <ServicesMain>
          <Container>
            <Grid>
              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <GridItem
                    mobileL={6}
                    mobileLStart={2}
                    desk={2}
                    deskStart={(itemIndex + 1) * 2}
                  >
                    <ServicesItem
                      index={itemIndex}
                      icon={tetrisPieces[itemIndex]}
                      label={item.name}
                      show={itemIndex === 0}
                      sectionInView={
                        vw < breakpoints.desk || store.servicesInView === 1
                      }
                      inView={
                        vw < breakpoints.desk || store.servicesInView === 1
                      }
                      collapsedText={item.additionalText}
                    >
                      {item.title && (
                        <>
                          <Heading3 as="h3">
                            <AnimateSplitText delay={itemIndex * 0.5}>
                              {item.title}
                            </AnimateSplitText>
                          </Heading3>

                          <Spacer size={20} />
                        </>
                      )}

                      <TextBody>
                        <AnimateSplitText delay={itemIndex * 0.5 + 0.1}>
                          {item.text.text}
                        </AnimateSplitText>
                      </TextBody>
                    </ServicesItem>
                  </GridItem>
                ))
              )}
            </Grid>
          </Container>
        </ServicesMain>
      </div>
    </ServicesWrapper>
  )
}

Services.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Services
