import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import Spacer from '../Spacer'
import { WorkListMain } from './index.style'
import { ContextDispatch } from '../../context/Context'
import WorkListItem from '../WorkListItem'
import SVGFlareGradient from '../_svgs/FlareGradient'

const WorkList = ({ items }) => {
  const {
    site: {
      siteMetadata: { routes },
    },
  } = useStaticQuery(graphql`
    query WorkListQuery {
      site {
        siteMetadata {
          routes {
            work
          }
        }
      }
    }
  `)
  const dispatch = useContext(ContextDispatch)
  const [hovered, setHovered] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleEnter = () => {
    setHovered(true)
    dispatch({ type: 'OPEN_CURSOR', payload: 'View' })
  }

  const handleLeave = () => {
    setHovered(false)
    dispatch({ type: 'CLOSE_CURSOR' })
  }

  return (
    <div data-scroll data-scroll-call="work" data-scroll-repeat>
      <Container>
        <Spacer size={[40, 85]} />

        <SVGFlareGradient index="0" color="#00FF94" />
        <SVGFlareGradient index="1" color="#FF00D6" />
        <SVGFlareGradient index="2" color="#FF3D00" />
        <SVGFlareGradient index="3" color="#00F0FF" />

        <Grid>
          <GridItem tabletL={6} tabletLStart={2}>
            <WorkListMain onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <WorkListItem
                    index={itemIndex}
                    item={item}
                    hovered={hovered}
                    workRoute={routes.work}
                    active={activeIndex === itemIndex}
                    delay={itemIndex * 0.3}
                    onMouseEnter={() => setActiveIndex(itemIndex)}
                  />
                ))
              )}
            </WorkListMain>
          </GridItem>
        </Grid>
      </Container>
    </div>
  )
}

WorkList.propTypes = {
  items: PropTypes.array,
}

export default WorkList
