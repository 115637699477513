import React from 'react'
import PropTypes from 'prop-types'
import { SpacerVHMain } from './index.style'

const SpacerVH = ({ value }) => {
  return <SpacerVHMain value={value} />
}

SpacerVH.propTypes = {
  value: PropTypes.number,
}

export default SpacerVH
