import styled from 'styled-components'

export const ClientsMain = styled.div``

export const ClientsTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const ClientsPlus = styled.div`
  transform: rotate(-16deg);
  transform-origin: 0% 50%;
  pointer-events: none;
`
