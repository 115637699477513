import React, { useEffect, useContext, useRef } from 'react'
import { gsap } from 'gsap'
import { Draggable, InertiaPlugin } from 'gsap/all'
import PropTypes from 'prop-types'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import AnimateSplitText from '../_animation/AnimateSplitText'
import Flow from '../Flow'
import Spacer from '../Spacer'
import TeamMember from '../TeamMember'
import Tetris from '../Tetris'
import { Heading2, TextBody } from '../TextStyles'
import {
  TeamIntroDesktop,
  TeamIntroMobile,
  TeamMain,
  TeamMembers,
  TeamWrapper,
} from './index.style'
import { ContextDispatch } from '../../context/Context'

const Team = ({ title, text, people }) => {
  const teamRef = useRef()
  const teamMembersWrapperRef = useRef()
  const teamMembersRef = useRef()
  const dispatch = useContext(ContextDispatch)

  const onMouseLeave = e => {
    dispatch({ type: 'CLOSE_CURSOR' })
  }

  const onMouseEnter = () => {
    dispatch({ type: 'OPEN_CURSOR_DRAG', payload: 'Drag' })
  }

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)

    Draggable.create(teamRef.current, {
      type: 'x',
      edgeResistance: 0.9,
      inertia: true,
    })

    const applyDraggableBounds = () => {
      Draggable.get(teamRef.current).applyBounds({
        minX:
          -teamMembersRef.current.offsetWidth +
          (window.innerWidth -
            teamMembersWrapperRef.current.getBoundingClientRect().left) -
          50,
        maxX: 0,
      })
    }

    applyDraggableBounds()

    window.addEventListener('resize', applyDraggableBounds)

    return () => {
      window.removeEventListener('resize', applyDraggableBounds)
    }
  }, [])

  return (
    <TeamWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-scroll
      data-scroll-call="team"
      data-scroll-repeat
    >
      <TeamIntroMobile>
        <Container>
          <Flow>
            <Heading2 as="h3">
              <AnimateSplitText>
                {title}
                <Tetris inline />
              </AnimateSplitText>
            </Heading2>

            <TextBody>
              <AnimateSplitText delay={0.3}>{text}</AnimateSplitText>
            </TextBody>
          </Flow>

          <Spacer size={40} />
        </Container>
      </TeamIntroMobile>

      <TeamMain ref={teamRef}>
        <Container>
          <Grid>
            <GridItem desk={4}>
              <TeamIntroDesktop>
                <Grid>
                  <GridItem desk={6} deskStart={3}>
                    <Heading2 as="h3">
                      <AnimateSplitText>
                        {title} <Tetris inline />
                      </AnimateSplitText>
                    </Heading2>
                  </GridItem>

                  <GridItem desk={4} deskStart={5}>
                    <TextBody>
                      <AnimateSplitText delay={0.3}>{text}</AnimateSplitText>
                    </TextBody>
                  </GridItem>
                </Grid>
              </TeamIntroDesktop>
            </GridItem>

            <GridItem desk={3} deskStart={6} ref={teamMembersWrapperRef}>
              <TeamMembers ref={teamMembersRef}>
                {React.Children.toArray(
                  people.map(person => (
                    <TeamMember
                      name={person.name}
                      role={person.role}
                      description={person.description.description}
                      image={person.photo.gatsbyImageData.images.fallback.src}
                      flareColor={person.flareColor}
                    />
                  ))
                )}
              </TeamMembers>
            </GridItem>
          </Grid>
        </Container>
      </TeamMain>
    </TeamWrapper>
  )
}

Team.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  people: PropTypes.array,
}

export default Team
