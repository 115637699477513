import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { ContextDispatch } from '../../context/Context'
import BlurImage from '../BlurImage'
import Spacer from '../Spacer'
import { TextBodyLarge, TextBodySmall } from '../TextStyles'
import {
  TeamMemberMain,
  TeamMemberTitle,
  TeamMemberHr,
  TeamMemberBio,
  TeamMemberPlus,
} from './index.style'
import Accordion from '../Accordion'

const TeamMember = ({ name, role, description, image, flareColor }) => {
  const dispatch = useContext(ContextDispatch)
  const [hovered, setHovered] = useState(false)
  const [open, setOpen] = useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
    dispatch({ type: 'OPEN_CURSOR', payload: open ? 'Close' : 'View' })
  }

  const handleMouseLeave = () => {
    setHovered(false)
    dispatch({ type: 'OPEN_CURSOR_DRAG', payload: 'Drag' })
  }

  const handleClick = () => {
    setOpen(!open)
    dispatch({ type: 'OPEN_CURSOR', payload: open ? 'View' : 'Close' })
  }

  return (
    <TeamMemberMain
      onMouseEnter={handleMouseEnter}
      onFocus={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleMouseLeave}
      onClick={handleClick}
      open={open}
    >
      <BlurImage
        id={name.replace(/\s+/g, '')}
        image={image}
        blurred={!open}
        flare={!open}
        flareColor={flareColor}
      />

      <Spacer size={[32, 64]} />

      <TeamMemberTitle>
        <TextBodyLarge>{name}</TextBodyLarge>

        <TextBodySmall>
          <TeamMemberPlus>+</TeamMemberPlus> {role}
        </TextBodySmall>
      </TeamMemberTitle>

      <Spacer size={[19, 32]} />

      <Accordion open={open}>
        <TeamMemberBio>
          <TextBodySmall>{description}</TextBodySmall>
        </TeamMemberBio>
      </Accordion>

      <Spacer size={[19, 32]} />

      <TeamMemberHr hovered={hovered} />
    </TeamMemberMain>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  flareColor: PropTypes.string,
}

export default TeamMember
