import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const TeamWrapper = styled.div`
  padding-top: 1rem;
  overflow: hidden;
`

export const TeamMain = styled.div`
  h3,
  p {
    user-select: none;
  }
`

export const TeamIntroMobile = styled.div`
  ${mq.desk} {
    display: none;
  }
`

export const TeamIntroDesktop = styled.div`
  display: none;

  ${mq.desk} {
    display: block;
  }
`

export const TeamMembers = styled.div`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  ${clamp('padding-bottom', 40, 132)}
`
