import React, { useEffect, useContext, useRef, useState } from 'react'
import gsap from 'gsap'
import IconAccordionToggle from '../_svgs/IconAccordionToggle'
import Spacer from '../Spacer'
import Accordion from '../Accordion'
import RichText from '../RichText'
import {
  ServicesItemContainer,
  ServicesItemHeader,
  ServicesItemLabelText,
  ServicesItemIcon,
  ServicesItemMain,
  ServicesItemContent,
  ServicesItemLabel,
} from './index.style'
import { ContextDispatch } from '../../context/Context'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { breakpoints } from '../../styles/vars/breakpoints.style'

const ServicesItem = ({
  children,
  index,
  label,
  inView,
  icon,
  sectionInView,
  show = false,
  collapsedText,
}) => {
  const dispatch = useContext(ContextDispatch)
  const [isDesktop, setIsDesktop] = useState(
    typeof window === 'undefined' ? null : window.innerWidth >= breakpoints.desk
  )
  const [isOpen, setIsOpen] = useState(show)
  const [accordionIsOpen, setAccordionIsOpen] = useState(false)
  const [contentIsOpen, setContentIsOpen] = useState(show)
  const contentRef = useRef(null)
  const contentInnerRef = useRef(null)

  useEffect(() => {
    const resize = () => {
      setIsDesktop(window.innerWidth >= breakpoints.desk)
    }

    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const handleItemToggle = () => {
    const clearInlineStyles = () => {
      gsap.set(contentRef.current, { clearProps: 'display,height' })
      gsap.set(contentInnerRef.current, { clearProps: 'opacity' })
    }

    if (isOpen) {
      const tl = gsap.timeline({
        onComplete: () => {
          setContentIsOpen(false)
          clearInlineStyles()
        },
      })

      tl.to(contentRef.current, {
        duration: 0.3,
        height: 0,
        ease: 'power3.out',
      }).to(
        contentInnerRef.current,
        {
          duration: 0.1,
          opacity: 0,
        },
        0
      )
    }

    if (!isOpen) {
      contentRef.current.style.display = 'block'
      const contentHeight = contentRef.current.offsetHeight
      contentRef.current.style.height = 0

      const tl = gsap.timeline({
        onComplete: () => {
          setContentIsOpen(true)
          clearInlineStyles()
        },
      })

      tl.to(contentRef.current, {
        duration: 0.3,
        height: contentHeight,
        ease: 'power3.out',
      }).fromTo(
        contentInnerRef.current,
        {
          duration: 0.15,
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.05,
          ease: 'power2.out',
        },
        0.1
      )
    }

    setIsOpen(!isOpen)
  }

  const handleItemClick = () => {
    if (window.innerWidth >= breakpoints.desk) {
      setAccordionIsOpen(!accordionIsOpen)
    }

    dispatch({
      type: 'OPEN_CURSOR',
      payload: !accordionIsOpen ? 'Close' : 'View',
    })
  }

  return (
    <ServicesItemContainer
      accordionIsOpen={accordionIsOpen}
      onClick={handleItemClick}
      onMouseEnter={() =>
        dispatch({
          type: 'OPEN_CURSOR',
          payload: accordionIsOpen ? 'Close' : 'View',
        })
      }
      onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR' })}
    >
      <div data-inview={sectionInView}>
        <ServicesItemHeader
          onClick={handleItemToggle}
          isOpen={isOpen}
          inView={inView}
        >
          <AnimateSlideIn delay={index * 0.5 + 0.1}>
            <ServicesItemLabel>
              {icon} <ServicesItemLabelText>{label}</ServicesItemLabelText>
            </ServicesItemLabel>
          </AnimateSlideIn>

          <ServicesItemIcon isOpen={isOpen}>
            <IconAccordionToggle open={isOpen} />
          </ServicesItemIcon>
        </ServicesItemHeader>
      </div>

      <ServicesItemMain
        ref={contentRef}
        open={contentIsOpen}
        data-inview={inView}
      >
        <ServicesItemContent ref={contentInnerRef} open={contentIsOpen}>
          {children}

          {collapsedText && isDesktop && (
            <Accordion open={accordionIsOpen}>
              <Spacer size={30} />
              <RichText content={collapsedText} delay={index * 0.5 + 0.1} />
            </Accordion>
          )}

          {collapsedText && !isDesktop && (
            <>
              <Spacer size={30} />
              <RichText content={collapsedText} delay={index * 0.5 + 0.1} />
            </>
          )}
        </ServicesItemContent>
      </ServicesItemMain>
    </ServicesItemContainer>
  )
}

export default ServicesItem
