import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import Container from '../_layouts/Container'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { Heading1, TextBodyLarge } from '../TextStyles'
import { animation } from '../../styles/vars/animation.style'
import {
  HeroDescription,
  HeroMain,
  HeroText,
  HeroTextBlock,
  HeroTextHeader,
  HeroTextWrapper,
} from './index.style'
import { CustomEase } from 'gsap/all'

const Hero = ({ text }) => {
  const wordPartDuration = 0.7
  const loadADelay = animation.siteLoadDelay + animation.pageEntryDuration + 1
  const loadBDelay = loadADelay + 1.2
  const loadCDelay = loadBDelay - 0.2
  const a1 = useRef()
  const b1 = useRef()
  const b2 = useRef()
  const c1 = useRef()
  const c2 = useRef()
  const d1 = useRef()
  const d2 = useRef()
  const e1 = useRef()
  const e2 = useRef()
  const f1 = useRef()
  const f2 = useRef()
  const f3 = useRef()

  useEffect(() => {
    const wordOffset = 0.15
    const tweenOffset = 0.55

    gsap.registerPlugin(GSDevTools, CustomEase)
    CustomEase.create('inOutDefault', '0.52, 0.01, 0, 1')
    gsap.defaults({ duration: wordPartDuration, ease: 'inOutDefault' })

    const tweenA = () => {
      const tl = gsap.timeline()

      tl.fromTo(
        a1.current,
        {
          opacity: 1,
          yPercent: 120,
        },
        {
          yPercent: 0,
        }
      ).to(a1.current, {
        yPercent: -120,
        delay: 1,
      })

      return tl
    }

    const tweenB = () => {
      const tl = gsap.timeline()

      tl.fromTo(
        b1.current,
        {
          opacity: 1,
          yPercent: 120,
        },
        {
          yPercent: 0,
        }
      )
        .fromTo(
          b2.current,
          {
            opacity: 1,
            xPercent: 120,
          },
          {
            xPercent: 0,
          },
          `-=${wordOffset}`
        )
        .to(b1.current, {
          yPercent: -120,
          delay: 0.5,
        })
        .to(
          b2.current,
          {
            yPercent: -120,
          },
          `-=${wordOffset}`
        )

      return tl
    }

    const tweenC = () => {
      const tl = gsap.timeline()

      tl.fromTo(
        c1.current,
        {
          opacity: 1,
          xPercent: -110,
        },
        {
          xPercent: 0,
        }
      )
        .fromTo(
          c2.current,
          {
            opacity: 1,
            yPercent: 120,
          },
          {
            yPercent: 0,
          },
          `-=${wordOffset}`
        )
        .to(c1.current, {
          yPercent: -120,
          delay: 0.5,
        })
        .to(
          c2.current,
          {
            yPercent: -120,
          },
          `-=${wordOffset}`
        )

      return tl
    }

    const tweenD = () => {
      const tl = gsap.timeline()

      tl.fromTo(
        d1.current,
        {
          opacity: 1,
          yPercent: 120,
        },
        {
          yPercent: 0,
        }
      )
        .fromTo(
          d2.current,
          {
            opacity: 1,
            yPercent: 120,
          },
          {
            yPercent: 0,
          },
          `-=${wordOffset}`
        )
        .to(d1.current, {
          yPercent: -120,
          delay: 0.5,
        })
        .to(
          d2.current,
          {
            yPercent: -120,
          },
          `-=${wordOffset}`
        )

      return tl
    }

    const tweenE = () => {
      const tl = gsap.timeline()

      tl.fromTo(
        e1.current,
        {
          opacity: 1,
          yPercent: 120,
        },
        {
          yPercent: 0,
        }
      )
        .fromTo(
          e2.current,
          {
            opacity: 1,
            xPercent: 120,
          },
          {
            xPercent: 0,
          },
          `-=${wordOffset}`
        )
        .to(e1.current, {
          yPercent: -120,
          delay: 0.5,
        })
        .to(
          e2.current,
          {
            yPercent: -120,
          },
          `-=${wordOffset}`
        )

      return tl
    }

    const tweenF = () => {
      const tl = gsap.timeline()

      tl.set(f3.current, { opacity: 1 })
        .fromTo(
          f1.current,
          {
            opacity: 1,
            yPercent: 120,
          },
          {
            yPercent: 0,
          }
        )
        .fromTo(
          f2.current,
          {
            opacity: 1,
            yPercent: 120,
          },
          {
            yPercent: 0,
          },
          `-=${wordOffset}`
        )
        .to(f3.current, {
          rotate: -15,
          delay: 0.2,
          duration: 0.2,
        })

      return tl
    }

    const timeline = gsap.timeline({
      id: 'homeHero',
      delay: loadBDelay,
    })

    timeline.add(tweenA())
    timeline.add(tweenB(), `-=${tweenOffset}`)
    timeline.add(tweenC(), `-=${tweenOffset}`)
    timeline.add(tweenD(), `-=${tweenOffset}`)
    timeline.add(tweenE(), `-=${tweenOffset}`)
    timeline.add(tweenF(), `-=${tweenOffset}`)

    // GSDevTools.create({ css: 'z-index: 100' })

    return () => {
      timeline.kill()
    }
  }, [loadADelay, loadBDelay])

  return (
    <HeroMain data-scroll data-scroll-call="hero" data-scroll-repeat>
      <Container>
        <Grid>
          <GridItem mobileL={6} mobileLStart={2} tabletL={6} tabletLStart={3}>
            <Heading1
              as="h2"
              aria-label="We are storytellers, strategists, creatives, marketers, technologists. We are Vivid+Co."
            >
              <AnimateSlideIn delay={loadADelay}>
                <HeroTextHeader>We are </HeroTextHeader>
              </AnimateSlideIn>

              <HeroTextWrapper>
                <HeroText>
                  <HeroTextBlock ref={a1}>Storytellers</HeroTextBlock>
                </HeroText>

                <HeroText>
                  <HeroTextBlock ref={b1}>Strat</HeroTextBlock>
                  <HeroTextBlock ref={b2}>egists</HeroTextBlock>
                </HeroText>

                <HeroText>
                  <HeroTextBlock ref={c1}>Crea</HeroTextBlock>
                  <HeroTextBlock ref={c2}>tives</HeroTextBlock>
                </HeroText>

                <HeroText>
                  <HeroTextBlock ref={d1}>Market</HeroTextBlock>
                  <HeroTextBlock ref={d2}>ers</HeroTextBlock>
                </HeroText>

                <HeroText>
                  <HeroTextBlock ref={e1}>Tech</HeroTextBlock>
                  <HeroTextBlock ref={e2}>nologists</HeroTextBlock>
                </HeroText>

                <HeroText>
                  <HeroTextBlock ref={f1}>Vivid</HeroTextBlock>
                  <HeroTextBlock ref={f2}>
                    <HeroTextBlock ref={f3}>+</HeroTextBlock>Co
                  </HeroTextBlock>
                </HeroText>
              </HeroTextWrapper>
            </Heading1>
          </GridItem>

          <GridItem mobileL={6} mobileLStart={3} tabletL={4} tabletLStart={5}>
            <HeroDescription>
              <TextBodyLarge maxWidth={18}>
                <AnimateSplitText delay={loadCDelay}>{text}</AnimateSplitText>
              </TextBodyLarge>
            </HeroDescription>
          </GridItem>
        </Grid>
      </Container>
    </HeroMain>
  )
}

Hero.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Hero
