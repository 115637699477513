import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { LogosItem, LogosMain } from './index.style'

gsap.registerPlugin(GSDevTools)

const Logos = ({ id, items, offset }) => {
  const logoRefs = useRef([])
  const tlDelay = 1

  useEffect(() => {
    const rowLength = 5
    let tl

    for (let i = 0; i < rowLength; i++) {
      const element = logoRefs.current[i]

      gsap.set(element, {
        yPercent: -100,
        xPercent: i * 100,
      })
    }

    const animateLogos = () => {
      if (logoRefs.current[0] === null) return

      const logo0 = logoRefs.current[0]
      const logo1 = logoRefs.current[1]
      const logo2 = logoRefs.current[2]
      const logo3 = logoRefs.current[3]
      const logo4 = logoRefs.current[4]
      const logo5 = logoRefs.current[5]
      const logo6 = logoRefs.current[6]
      const logo7 = logoRefs.current[7]
      const logo8 = logoRefs.current[8]
      const logo9 = logoRefs.current[9]

      const pushToEnd = i => {
        logoRefs.current.push(logoRefs.current.splice(i, 1)[0])
      }

      const moveInArray = (oldIndex, newIndex) => {
        logoRefs.current.splice(
          newIndex,
          0,
          logoRefs.current.splice(oldIndex, 1)[0]
        )
      }

      const resetArray = () => {
        pushToEnd(0)
        pushToEnd(0)
        pushToEnd(1)
        pushToEnd(1)
        pushToEnd(3)
        moveInArray(1, 0)
        moveInArray(4, 2)
      }

      if (tl) {
        tl.clear()
        tl.kill()

        logoRefs.current.forEach((logo, logoIndex) => {
          if (logoIndex > 4) {
            gsap.set(logo, {
              clearProps: 'transform',
            })
          } else {
            gsap.set(logo, {
              yPercent: -100,
              xPercent: logoIndex * 100,
            })
          }
        })
      }

      tl = gsap.timeline({
        id: id,
        delay: tlDelay,
        defaults: {
          duration: 1,
        },
        onStart: () => {},
        onComplete: () => {
          resetArray()
          gsap.delayedCall(1, animateLogos)
        },
      })

      // A
      tl.set(logo5, {
        xPercent: 100,
        yPercent: 0,
      })
        .to(logo5, {
          yPercent: -100,
        })
        .to(
          logo1,
          {
            yPercent: -200,
          },
          '<'
        )

      // B
      tl.set(
        logo6,
        {
          xPercent: 300,
          yPercent: 0,
        },
        `+=${tlDelay}`
      )
        .to(logo3, {
          xPercent: 400,
        })
        .to(
          logo4,
          {
            yPercent: -200,
          },
          '<'
        )
        .to(
          logo6,
          {
            yPercent: -100,
          },
          '<'
        )

      // C
      tl.set(
        logo7,
        {
          xPercent: 100,
          yPercent: 0,
        },
        `+=${tlDelay}`
      )
      tl.to(logo5, {
        xPercent: 0,
      })
        .to(
          logo0,
          {
            yPercent: -200,
          },
          '<'
        )
        .to(
          logo7,
          {
            yPercent: -100,
          },
          '<'
        )

      // D
      tl.set(
        logo8,
        {
          xPercent: 400,
          yPercent: 0,
        },
        `+=${tlDelay}`
      )
        .to(logo3, {
          yPercent: -200,
        })
        .to(
          logo8,
          {
            yPercent: -100,
          },
          '<'
        )

      // E
      tl.set(
        logo9,
        {
          xPercent: 200,
          yPercent: 0,
        },
        `+=${tlDelay}`
      )
        .to(logo2, {
          xPercent: 100,
        })
        .to(
          logo7,
          {
            yPercent: -200,
          },
          '<'
        )
        .to(
          logo9,
          {
            yPercent: -100,
          },
          '<'
        )

      // GSDevTools.create({ animation: id, css: 'z-index: 100' })
    }

    setTimeout(
      () => {
        animateLogos()
      },
      offset ? tlDelay * 3 * 1000 : 0
    )

    return () => {
      if (tl) {
        tl.clear()
        tl.kill()
        tl = null
      }
    }
  }, [id, offset])

  return (
    <LogosMain>
      {React.Children.toArray(
        items.map((logo, logoIndex) => (
          <LogosItem ref={ref => (logoRefs.current[logoIndex] = ref)}>
            <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} />
          </LogosItem>
        ))
      )}
    </LogosMain>
  )
}

Logos.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  offset: PropTypes.bool,
}

Logos.defaultProps = {
  offset: false,
}

export default Logos
