import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import { ContextDispatch } from '../../context/Context'
import { CareersItemMain } from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'

const CareersItem = ({ url, title, delay }) => {
  const dispatch = useContext(ContextDispatch)
  const [hovered, setHovered] = useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
    dispatch({
      type: 'OPEN_CURSOR_ARROW',
      payload: 'More info',
    })
  }

  const handleMouseLeave = () => {
    setHovered(false)
    dispatch({ type: 'CLOSE_CURSOR' })
  }

  return (
    <CareersItemMain
      href={url}
      onMouseEnter={handleMouseEnter}
      onFocus={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleMouseLeave}
      hovered={hovered}
      target="_blank"
    >
      <AnimateSplitText delay={delay}>{title}</AnimateSplitText>
      <IconArrowheadRight />
    </CareersItemMain>
  )
}

CareersItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  delay: PropTypes.number,
}

CareersItem.defaultProps = {
  delay: 0,
}

export default CareersItem
