import React from 'react'
import PropTypes from 'prop-types'
import { Jobs, JobsItem } from './index.style'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import Tetris from '../Tetris'
import RichText from '../RichText'
import { Heading2, Heading3 } from '../TextStyles'
import Spacer from '../Spacer'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateSplitText from '../_animation/AnimateSplitText'
import CareersItem from '../CareersItem'

const Careers = ({ title, items, inactiveText }) => (
  <div data-scroll data-scroll-call="careers" data-scroll-repeat>
    <Container>
      <Grid>
        <GridItem tabletL={5} desk={4} deskStart={2} deskL={3} deskLStart={2}>
          <Heading3 as="h3">
            <AnimateSplitText>Join us</AnimateSplitText>
          </Heading3>

          <Spacer size={[32, 58]} />

          <Heading2 as="h2">
            <AnimateSplitText>
              {title}
              <Tetris inline />
            </AnimateSplitText>
          </Heading2>
        </GridItem>

        <GridItem tabletL={3} desk={2} deskStart={6}>
          {items ? (
            <Jobs>
              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <JobsItem>
                    <AnimateSlideIn delay={itemIndex * 0.1}>
                      <CareersItem
                        url={item.url}
                        title={item.role}
                        delay={itemIndex * 0.1}
                      />
                    </AnimateSlideIn>
                  </JobsItem>
                ))
              )}
            </Jobs>
          ) : (
            <>
              <Spacer size={[32, 130]} />
              <RichText content={inactiveText} />
            </>
          )}
        </GridItem>
      </Grid>
    </Container>
  </div>
)

Careers.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  inactiveText: PropTypes.object,
}

export default Careers
