import React from 'react'
import PropTypes from 'prop-types'
import { FlareGradientMain } from './index.style'

const SVGFlareGradient = ({ index, color }) => (
  <FlareGradientMain
    width="560"
    height="746"
    viewBox="0 0 560 746"
    aria-hidden
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <radialGradient
        id={`workFlareGradient${index}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(625 -19.4999) rotate(142.355) scale(1278.72 41.8213)"
      >
        <stop stopColor={color} />
        <stop stopColor={color} stopOpacity="0" offset="1" />
      </radialGradient>
    </defs>
  </FlareGradientMain>
)

SVGFlareGradient.propTypes = {
  index: PropTypes.string,
  color: PropTypes.string,
}

SVGFlareGradient.defaultProps = {
  color: '#FF6B00',
}

export default SVGFlareGradient
