import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const Advisor = styled.div`
  padding-top: 1.6rem;

  ${mq.desk} {
    max-width: 76em;
    padding-top: 0;
    padding-bottom: 6.4rem;
  }
`
