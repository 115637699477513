import React, { useRef, useEffect, useState } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { BlurImageMain, BlurImageSVG, BlurImageWrapper } from './index.style'
import { isDesktop } from 'react-device-detect'

const BlurImage = ({ id, image, blurred, flare, flareColor }) => {
  const elRef = useRef()
  const maskRef = useRef()
  const [maskRadius, setMaskRadius] = useState(150)
  // const [cx, setCx] = useState(0)
  // const [cy, setCy] = useState(0)
  const [cxRadial, setCxRadial] = useState(0)
  const [cyRadial, setCyRadial] = useState(0)
  const [hovered, setHovered] = useState(false)
  const [offset, setOffset] = useState([0, 0])

  useEffect(() => {
    const handleResize = () => {
      setMaskRadius(elRef.current.offsetWidth * 0.45)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
    setOffset([0, 0])
  }

  const handleMouseMove = e => {
    const mouseX = e.clientX
    const mouseY = e.clientY
    const offsetX = elRef.current.getBoundingClientRect().left
    const offsetY = elRef.current.getBoundingClientRect().top
    const lerpScale = 0.1

    // setCx(cx + (mouseX - offsetX - cx) * lerpScale)
    // setCy(cy + (mouseY - offsetY - cy) * lerpScale)

    const xNorm =
      gsap.utils.normalize(0, elRef.current.offsetWidth, mouseX - offsetX) * 2 -
      1
    const yNorm =
      gsap.utils.normalize(0, elRef.current.offsetHeight, mouseY - offsetY) *
        2 -
      1

    setCxRadial(cxRadial + (-xNorm * 0.3 + 0.2 - cxRadial) * lerpScale)
    setCyRadial(cyRadial + (-yNorm * 0.5 - 0.3 - cyRadial) * lerpScale)

    setOffset([xNorm, -yNorm])
  }

  return (
    <BlurImageWrapper
      ref={elRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <BlurImageMain offset={offset} hovered={hovered}>
        <BlurImageSVG>
          <defs>
            <filter
              id={`${id}circleBlur`}
              x="-40%"
              y="-40%"
              width="180%"
              height="180%"
            >
              <feGaussianBlur stdDeviation="50" />
            </filter>

            <filter id={`${id}imageBlur`}>
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="1 0 0 0 0 
                        0 1 0 0 0 
                        0 0 1 0 0 
                        0 0 0 2 0"
              />
            </filter>

            <mask id={`${id}circleMask`}>
              <rect fill="white" width="100%" height="100%" />
              <circle
                className="mask"
                ref={maskRef}
                cx="50%"
                cy="50%"
                r={maskRadius}
                fill="black"
                filter={`url(#${id}circleBlur)`}
                opacity={blurred ? 1 : 0}
              />
            </mask>

            <radialGradient
              id={`${id}flare`}
              className="gradient"
              cx={cxRadial}
              cy={cyRadial}
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${150.705} ${296.548} ${43.589}) scale(845.075 90.706)`}
            >
              <stop stopColor={flareColor} stopOpacity={0.8} />
              <stop offset="1" stopColor={flareColor} stopOpacity="0" />
            </radialGradient>
          </defs>

          <image href={image} width="100%" />

          {isDesktop && (
            <image
              className="blur"
              href={image}
              width="100%"
              filter={`url(#${id}imageBlur)`}
              mask={`url(#${id}circleMask)`}
              opacity={blurred ? 1 : 0}
            />
          )}

          <g className="flare" opacity={flare ? 1 : 0}>
            <rect
              width="100%"
              height="100%"
              fill={`url(#${id}flare)`}
              style={{ mixBlendMode: 'screen' }}
            />
          </g>
        </BlurImageSVG>
      </BlurImageMain>
    </BlurImageWrapper>
  )
}

BlurImage.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  blurred: PropTypes.bool,
  flare: PropTypes.bool,
  flareColor: PropTypes.string,
}

BlurImage.defaultProps = {
  blurred: true,
  flareColor: '#AD00FF',
}

export default BlurImage
