import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const ServicesItemContainer = styled.div`
  ${mq.desk} {
    position: relative;
    padding-bottom: 5.6rem;
    border-bottom: 0.1rem solid ${transparentize(0.8, colors.light)};

    :hover::before {
      transform: scaleX(1);
    }

    ::before {
      content: '';
      position: absolute;
      bottom: -0.1rem;
      right: 0;
      left: 0;
      transform-origin: 0% 50%;
      transform: ${props =>
        props.accordionIsOpen ? 'scaleX(1)' : 'scaleX(0)'};
      display: block;
      height: 0.1rem;
      background-color: ${colors.light};
      opacity: 0.9;
      transition: transform 0.15s ${easings.inOut.default};
    }
  }

  & + & {
    margin-top: 3.2rem;
  }
`

export const ServicesItemLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
  color: currentColor;
  transition: color 0.3s ease;

  ${mq.desk} {
    margin-right: 0;
  }
`

export const ServicesItemHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-left: 0;
  color: ${({ isOpen }) => (isOpen ? colors.light : colors.grey)};
  border: none;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  border-color: ${({ isOpen }) => (isOpen ? 'transparent' : colors.grey)};
  background: transparent;
  transition: border-color 0.15s ease;

  ${mq.desk} {
    pointer-events: none;
    color: ${({ inView }) => (inView ? colors.light : colors.grey)};
    border: none;
    border-color: ${({ inView }) => (inView ? 'transparent' : colors.grey)};
  }

  :hover,
  :focus,
  :active {
    outline: none;
  }
`

export const ServicesItemLabelText = styled.p`
  margin-left: 1.9rem;
  padding-top: 0.3em;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: left;

  ${mq.desk} {
    ${clamp('font-size', 15, 20, breakpoints.desk)}
  }
`

export const ServicesItemIcon = styled.div`
  transform: ${props => (props.isOpen ? 'rotate(-90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;

  ${mq.desk} {
    display: none;
  }

  path {
    transition: opacity 0.15s ease;
  }
`

export const ServicesItemMain = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};

  ${mq.desk} {
    display: block;
  }
`

export const ServicesItemContent = styled.div`
  opacity: ${({ open }) => (open ? 1 : 0)};

  ${mq.deskMax} {
    padding-bottom: 1.6rem;
  }

  ${mq.desk} {
    max-width: 40rem;
    opacity: 1;
  }
`
