import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const AccordionItemContainer = styled.div`
  ${mq.desk} {
    border-bottom: 0.1rem solid transparent;
    transition: border-color 0.2s ${easings.inOut.default}
      ${props => props.delay}s;

    [data-inview='true'] & {
      border-color: ${transparentize(0.4, colors.grey)};
    }
  }

  & + & {
    margin-top: 3.2rem;

    ${mq.desk} {
      margin-top: 0;
    }
  }
`

export const AccordionItemHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 0;
  color: ${({ isOpen }) => (isOpen ? colors.light : colors.grey)};
  border: none;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  border-color: ${({ isOpen }) => (isOpen ? 'transparent' : colors.grey)};
  background: transparent;
  transition: border-color 0.15s ease;

  ${mq.desk} {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    color: ${colors.light};
    border: none;
  }

  :hover,
  :focus,
  :active {
    outline: none;
  }
`

export const AccordionItemLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
  color: currentColor;
  text-align: left;
  transition: color 0.3s ease;
`

export const AccordionItemIcon = styled.div`
  transform: ${props => (props.isOpen ? 'rotate(-90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;

  ${mq.desk} {
    display: none;
  }

  path {
    transition: opacity 0.15s ease;
  }
`

export const AccordionItemMain = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow: hidden;
`

export const AccordionItemContent = styled.div`
  opacity: ${({ open }) => (open ? 1 : 0)};
`
