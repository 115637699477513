import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp, getClampValue } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { TextBodySmall } from '../../styles/vars/textStyles.style'

export const TeamMemberMain = styled.div`
  position: relative;
  ${clamp('width', 233, 380)}
  transition: transform 0.3s ${easings.inOut.default};

  :nth-child(odd) {
    transform: ${({ open }) =>
      open ? 'translateY(0)' : `translateY(${getClampValue(40, 132)})`};
  }

  & + & {
    ${clamp('margin-left', 48, 128)}
  }
`

export const TeamMemberTitle = styled.button`
  display: flex;
  align-items: flex-start;
  text-align: left;
  background-color: transparent;
  border: none;

  ${TextBodySmall} {
    position: relative;
    flex: 1;
    margin-left: 0.4em;
    padding-left: 0.75em;
  }
`

export const TeamMemberPlus = styled.span`
  position: absolute;
  top: 0;
  left: 0;
`

export const TeamMemberHr = styled.div`
  position: relative;
  height: 0.1rem;
  background-color: ${transparentize(0.4, colors.grey)};

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${colors.light};
    transform: ${({ hovered }) => (hovered ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: ${({ hovered }) => (hovered ? '0% 50%' : '100% 50%')};
    transition: transform 0.4s ${easings.inOut.default};
  }
`

export const TeamMemberBio = styled.div``
