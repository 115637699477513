import React, { useState, useContext } from 'react'
import IconAccordionToggle from '../_svgs/IconAccordionToggle'
import {
  AccordionItemContainer,
  AccordionItemHeader,
  AccordionItemLabel,
  AccordionItemIcon,
} from './index.style'
import Accordion from '../Accordion'
import { Heading3 } from '../TextStyles'
import { ContextDispatch } from '../../context/Context'
import AnimateSplitText from '../_animation/AnimateSplitText'

const AccordionItem = ({ children, label, index, show = false }) => {
  const dispatch = useContext(ContextDispatch)
  const [isOpen, setIsOpen] = useState(show)

  const handleItemToggle = e => {
    dispatch({ type: 'OPEN_CURSOR', payload: !isOpen ? 'Close' : 'View' })
    setIsOpen(!isOpen)
  }

  const buildOnDelay = (index + 1) * 0.2

  return (
    <AccordionItemContainer delay={buildOnDelay}>
      <AccordionItemHeader
        onClick={handleItemToggle}
        onMouseEnter={() =>
          dispatch({ type: 'OPEN_CURSOR', payload: isOpen ? 'Close' : 'View' })
        }
        onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR' })}
        isOpen={isOpen}
      >
        <AccordionItemLabel>
          <Heading3>
            <AnimateSplitText delay={buildOnDelay}>{label}</AnimateSplitText>
          </Heading3>
        </AccordionItemLabel>

        <AccordionItemIcon isOpen={isOpen}>
          <IconAccordionToggle open={isOpen} />
        </AccordionItemIcon>
      </AccordionItemHeader>

      <Accordion open={isOpen}>{children}</Accordion>
    </AccordionItemContainer>
  )
}

export default AccordionItem
