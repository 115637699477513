import React from 'react'
import PropTypes from 'prop-types'
import { HomeBrandText } from './index.style'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import { Heading2, TextBodyLarge } from '../TextStyles'
import Tetris from '../Tetris'
import AnimateSplitText from '../_animation/AnimateSplitText'

const HomeBrand = ({ heading, text }) => (
  <div data-scroll data-scroll-call="brand" data-scroll-repeat>
    <Container>
      <Grid>
        <GridItem tabletL={5} tabletLStart={3} tabletLOrder={2}>
          <Heading2 maxWidth={7} as="h2">
            <AnimateSplitText delay={0.4}>
              {heading}
              <Tetris inline />
            </AnimateSplitText>
          </Heading2>
        </GridItem>

        <GridItem tabletL={3} tabletLStart={2} tabletLOrder={1}>
          <HomeBrandText>
            <TextBodyLarge maxWidth={9.2}>
              <AnimateSplitText delay={0.2}>{text}</AnimateSplitText>
            </TextBodyLarge>
          </HomeBrandText>
        </GridItem>
      </Grid>
    </Container>
  </div>
)

HomeBrand.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default HomeBrand
