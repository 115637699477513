import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const HomeBrandText = styled.div`
  padding-top: 4rem;

  ${mq.tabletL} {
    padding-top: 0;
    padding-bottom: 4.8rem;
  }
`
