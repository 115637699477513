import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/Hero'
import Seo from '../components/Seo'
import HomeBrand from '../components/HomeBrand'
import Footer from '../components/Footer'
import HomeStrategy from '../components/HomeStrategy'
import HomeProcess from '../components/HomeProcess'
import Services from '../components/Services'
import ScrollSection from '../components/ScrollSection'
import WorkList from '../components/WorkList'
import Careers from '../components/Careers'
import Team from '../components/Team'
import { scrollTo } from '../utils/utils'
import Clients from '../components/Clients'
import Advisors from '../components/Advisors'
import SpacerVH from '../components/SpacerVH'
import Spacer from '../components/Spacer'
import { animation } from '../styles/vars/animation.style'
import { ContextDispatch, ContextState } from '../context/Context'

const IndexPage = ({ data, location }) => {
  const {
    title,
    seoDescription: { seoDescription },
    heroText: { heroText },
    brandHeading,
    brandText: { brandText },
    strategyText: { strategyText },
    strategyHeading: { strategyHeading },
    services,
    processText: { processText },
    processHeading: { processHeading },
    caseStudies,
    clientLogos,
    publicationLogos,
    teamHeading,
    teamText: { teamText },
    teamMembers,
    advisorsNotableInvestors,
    careersHeading,
    jobs,
    noJobsText,
  } = data.contentfulPageHome
  const { blocks } = data.site.siteMetadata
  const store = useContext(ContextState)
  const dispatch = useContext(ContextDispatch)

  useEffect(() => {
    const query = location.search.length ? location.search.substring(1) : null
    let timeout = null

    if (store.previousPage?.indexOf('/work/') > -1) {
      timeout = setTimeout(() => {
        scrollTo('work', dispatch)
        dispatch({ type: 'SET_PREVIOUS_PAGE', url: '/' })
      }, (animation.siteLoadDelay + animation.pageEntryDuration) * 1000)
    }

    if (query) {
      timeout = setTimeout(() => {
        scrollTo(query, dispatch)
      }, (animation.siteLoadDelay + animation.pageEntryDuration) * 1000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [location.search, store.previousPage, dispatch])

  useEffect(() => {
    dispatch({ type: 'END_SCROLL_TO' })
  }, [dispatch])

  return (
    <>
      <Seo title={title} description={seoDescription} />

      <ScrollSection>
        <Hero text={heroText} />
      </ScrollSection>

      <SpacerVH value={50} />

      <ScrollSection>
        <HomeBrand heading={brandHeading} text={brandText} />
      </ScrollSection>

      <SpacerVH value={50} />

      <ScrollSection id={blocks[0].id}>
        <HomeStrategy heading={strategyHeading} text={strategyText} />
      </ScrollSection>

      <SpacerVH value={5} />

      <ScrollSection trackInView={false}>
        <Services items={services} />
      </ScrollSection>

      <SpacerVH value={20} />

      <ScrollSection id={blocks[1].id}>
        <HomeProcess heading={processHeading} text={processText} />
      </ScrollSection>

      <ScrollSection>
        <WorkList items={caseStudies} />
      </ScrollSection>

      <SpacerVH value={20} />

      <ScrollSection id={blocks[1].id}>
        <Clients clients={clientLogos} publications={publicationLogos} />
      </ScrollSection>

      <SpacerVH value={50} />

      <ScrollSection id={blocks[2].id}>
        <Team title={teamHeading} text={teamText} people={teamMembers} />
      </ScrollSection>

      <Spacer size={[64, 132]} />

      <ScrollSection>
        <Advisors items={advisorsNotableInvestors} />
      </ScrollSection>

      <SpacerVH value={50} />

      <ScrollSection id={blocks[3].id}>
        <Careers
          title={careersHeading}
          items={jobs}
          inactiveText={noJobsText}
        />
      </ScrollSection>

      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query Home {
    site {
      siteMetadata {
        blocks {
          id
        }
      }
    }
    contentfulPageHome {
      title
      seoDescription {
        seoDescription
      }
      heroText {
        heroText
      }
      brandHeading
      brandText {
        brandText
      }
      strategyText {
        strategyText
      }
      strategyHeading {
        strategyHeading
      }
      services {
        title
        name
        text {
          text
        }
        additionalText {
          raw
        }
      }
      processText {
        processText
      }
      processHeading {
        processHeading
      }
      caseStudies {
        name
        slug
        featureImage {
          gatsbyImageData(width: 280)
        }
      }
      clientLogos {
        title
        gatsbyImageData(height: 80)
      }
      publicationLogos {
        gatsbyImageData(height: 80)
        title
      }
      teamHeading
      teamText {
        teamText
      }
      teamMembers {
        name
        role
        description {
          description
        }
        photo {
          gatsbyImageData(width: 760, placeholder: BLURRED)
        }
        flareColor
      }
      advisorsNotableInvestors {
        name
        bio {
          raw
        }
      }
      careersHeading
      jobs {
        role
        url
      }
      noJobsText {
        raw
      }
    }
  }
`
