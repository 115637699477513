import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const HeroMain = styled.div`
  position: relative;
  ${clamp('padding-top', 266, 335)}
`

export const HeroTextHeader = styled.span`
  display: block;
`

export const HeroTextWrapper = styled.span`
  position: relative;
  display: block;
  height: 1.2em;
  margin-top: 0.1em;
`

export const HeroText = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 1.2em;
  overflow: hidden;
`

export const HeroTextBlock = styled.span`
  display: inline-flex;
  opacity: 0;
`

export const HeroDescription = styled.div`
  position: relative;
  margin-top: 4rem;
  padding-left: 4.4rem;

  ${mq.mobileL} {
    padding-left: 0;
  }

  ${mq.tabletL} {
    margin-top: 0;
  }

  svg {
    position: absolute;
    top: 0.7rem;
    left: 0;

    ${mq.mobileL} {
      left: -6.4rem;
      transform: translateX(-100%);
    }
  }
`
