import React from 'react'
import PropTypes from 'prop-types'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { Heading4 } from '../TextStyles'
import Spacer from '../Spacer'
import AccordionItem from '../AccordionItem'
import RichText from '../RichText'
import { Advisor } from './index.style'

const Advisors = ({ items }) => (
  <div data-scroll data-scroll-call="advisors" data-scroll-repeat>
    <Container>
      <Grid>
        <GridItem tabletL={5} tabletLStart={2}>
          <Heading4 as="h3" maxWidth={8}>
            <AnimateSplitText>Advisors + Notable Investors</AnimateSplitText>
          </Heading4>

          <Spacer size={[50, 64]} />
        </GridItem>

        <GridItem tabletL={5} tabletLStart={3}>
          {React.Children.toArray(
            items.map((item, itemIndex) => (
              <AccordionItem label={item.name} index={itemIndex}>
                <Advisor>
                  <RichText content={item.bio} textSize="regular" />
                </Advisor>
              </AccordionItem>
            ))
          )}
        </GridItem>
      </Grid>
    </Container>
  </div>
)

Advisors.propTypes = {
  items: PropTypes.array,
}

export default Advisors
