import React from 'react'
import PropTypes from 'prop-types'
import { HomeStrategyText } from './index.style'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import { Heading2, TextBodyLarge } from '../TextStyles'
import Tetris from '../Tetris'
import AnimateSplitText from '../_animation/AnimateSplitText'

const HomeStrategy = ({ heading, text }) => (
  <div id="strategy" data-scroll data-scroll-call="strategy" data-scroll-repeat>
    <Container>
      <Grid>
        <GridItem tabletL={6} tabletLStart={2}>
          <Heading2 maxWidth={11} as="h2">
            <AnimateSplitText>
              {heading}
              <Tetris inline />
            </AnimateSplitText>
          </Heading2>
        </GridItem>

        <GridItem tabletL={3} tabletLStart={5}>
          <HomeStrategyText>
            <TextBodyLarge maxWidth={18}>
              <AnimateSplitText delay={0.4}>{text}</AnimateSplitText>
            </TextBodyLarge>
          </HomeStrategyText>
        </GridItem>
      </Grid>
    </Container>
  </div>
)

HomeStrategy.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default HomeStrategy
