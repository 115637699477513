import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const ServicesWrapper = styled.div`
  ${mq.desk} {
    ${setVh('padding-top', 25)}
  }
`

export const ServicesMain = styled.div`
  padding-top: 6vw;

  ${mq.desk} {
    padding-top: 0;
    ${setVh('padding-bottom', 50)}
  }
`

export const ServicesSpacer = styled.div`
  ${mq.desk} {
    ${setVh('height', 20)}
  }
`
