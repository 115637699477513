import React, { useState, useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import PageTransitionLink from '../PageTransitionLink'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { Heading3 } from '../TextStyles'
import {
  WorkListItemMain,
  WorkListItemText,
  WorkListItemMask,
  WorkListItemImage,
  WorkListItemBorder,
  WorkListItemIcon,
  WorkListItemContent,
  WorkListItemMaskWrap,
  WorkListFlare,
  WorkListFlareSVG,
} from './index.style'

const WorkListItem = ({
  index,
  item,
  hovered,
  workRoute,
  active,
  delay,
  onMouseEnter,
}) => {
  const elRef = useRef()
  const [entered, setEntered] = useState(false)
  const [offset, setOffset] = useState([0, 0])
  const gradientIndex = index % 4

  const handleEnter = () => {
    onMouseEnter()
    setEntered(true)
  }

  const handleLeave = () => {
    setEntered(false)
  }

  const handleMouseMove = e => {
    const mouseX = e.clientX
    const mouseY = e.clientY
    const offsetX = elRef.current.getBoundingClientRect().left
    const offsetY = elRef.current.getBoundingClientRect().top
    const xNorm =
      gsap.utils.normalize(0, elRef.current.offsetWidth, mouseX - offsetX) * 2 -
      1
    const yNorm =
      gsap.utils.normalize(0, elRef.current.offsetHeight, mouseY - offsetY) *
        2 -
      1

    setOffset([xNorm, -yNorm])
  }

  return (
    <WorkListItemMain
      ref={elRef}
      onMouseEnter={handleEnter}
      onFocus={handleEnter}
      onMouseLeave={handleLeave}
      onBlur={handleLeave}
      onMouseMove={handleMouseMove}
    >
      <WorkListItemContent>
        <WorkListItemBorder show={entered} />

        <AnimateSlideIn delay={delay}>
          <PageTransitionLink to={`${workRoute}/${item.slug}`}>
            <Heading3 as="h3">
              <WorkListItemText>
                <AnimateSplitText>{item.name}</AnimateSplitText>
              </WorkListItemText>
            </Heading3>

            <WorkListItemIcon>
              <IconArrowheadRight />
            </WorkListItemIcon>
          </PageTransitionLink>
        </AnimateSlideIn>
      </WorkListItemContent>

      <WorkListItemMaskWrap>
        {item.featureImage && (
          <WorkListItemMask offset={offset}>
            <WorkListItemImage open={hovered} active={active}>
              <GatsbyImage
                image={item.featureImage.gatsbyImageData}
                alt={item.name}
              />

              <WorkListFlare>
                <WorkListFlareSVG
                  width="560"
                  height="746"
                  viewBox="0 0 560 746"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  mousePos={offset}
                >
                  <path
                    fill={`url(#workFlareGradient${gradientIndex})`}
                    d="M0 0h560v746H0z"
                  />
                </WorkListFlareSVG>
              </WorkListFlare>
            </WorkListItemImage>
          </WorkListItemMask>
        )}
      </WorkListItemMaskWrap>
    </WorkListItemMain>
  )
}

WorkListItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  hovered: PropTypes.bool,
  workRoute: PropTypes.string,
  active: PropTypes.bool,
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
}

export default WorkListItem
