import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const LogosMain = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  pointer-events: none;
  list-style: none;
`

export const LogosItem = styled.li`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3333%;
  height: 100%;

  ${mq.tabletP} {
    width: 20%;
  }

  > div > div:nth-child(2) {
    background-color: transparent !important;
  }
`
