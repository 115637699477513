import React from 'react'

const TetrisPieceC = ({
  width = 11,
  height = 11,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 11"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <rect width="5" height="5" rx="1" fill={fill} />
      <rect x="6" width="5" height="5" rx="1" fill={fill} />
      <rect x="6" y="6" width="5" height="5" rx="1" fill={fill} />
      <rect y="6" width="5" height="5" rx="1" fill={fill} />
    </svg>
  )
}

export default TetrisPieceC
