import React from 'react'
import PropTypes from 'prop-types'
import Container from '../_layouts/Container'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { Heading3 } from '../TextStyles'
import { ClientsMain, ClientsPlus, ClientsTitle } from './index.style'
import Logos from '../Logos'

const Clients = ({ clients, publications, inView }) => (
  <ClientsMain data-scroll data-scroll-call="clients" data-scroll-repeat>
    <Container>
      <Grid>
        <GridItem tabletP={1} desk={2} deskStart={2}>
          <ClientsTitle>
            <Heading3 as="h3">
              <AnimateSplitText>Our clients</AnimateSplitText>
            </Heading3>
          </ClientsTitle>
        </GridItem>

        <GridItem tabletP={7} desk={4}>
          <AnimateSlideIn delay={0.1}>
            <Logos id="clients" items={clients} inView={inView} />
          </AnimateSlideIn>
        </GridItem>

        <GridItem desk={7} deskStart={2}>
          <ClientsPlus>
            <Heading3 aria-hidden>
              <AnimateSplitText delay={0.2}>+</AnimateSplitText>
            </Heading3>
          </ClientsPlus>
        </GridItem>

        <GridItem tabletP={1} desk={2} deskStart={2}>
          <ClientsTitle>
            <Heading3 as="h3">
              <AnimateSplitText delay={0.3}>Featured in</AnimateSplitText>
            </Heading3>
          </ClientsTitle>
        </GridItem>

        <GridItem tabletP={7} desk={4}>
          <AnimateSlideIn delay={0.4}>
            <Logos
              id="publications"
              items={publications}
              offset
              inView={inView}
            />
          </AnimateSlideIn>
        </GridItem>
      </Grid>
    </Container>
  </ClientsMain>
)

Clients.propTypes = {
  clients: PropTypes.array,
  publications: PropTypes.array,
  inView: PropTypes.bool,
}

export default Clients
